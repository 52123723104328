@use '@angular/material' as mat;

:root {
    --blackColor: #0F0E17;
    --whiteColor: #FFFFFF;
    --indigo100: #E0EAFF;
    --neonBlueColor: #5865F1;
    --neonBlue50: #EEF0FE;
    --neonBlue100: #CBCFFB;
    --neonBlue300: #8F98F6;
    --neonBlue600: #505CDB;
    --neonBlue700: #3E48AB;
    --warning50: #FFFAEB;
    --warningColor: #F79009;
    --warning600: #DC6803;
    --warning700: #B54708;
    --gray25: #FCFCFD;
    --gray50: #F9FAFB;
    --gray100: #F2F4F7;
    --gray200: #EAECF0;
    --gray300: #D0D5DD;
    --gray400: #98A2B3;
    --gray500: #667085;
    --gray600: #475467;
    --gray700: #344054;
    --gray800: #1D2939;
    --gray900: #101828;
    --success25: #F6FEF9;
    --success50: #ECFDF3;
    --success200: #A6F4C5;
    --success500: #12B76A;
    --success600: #039855;
    --success700: #027A48;
    --error50: #FEF3F2;
    --errorColor: #F04438;
    --error600: #D92D20;
    --error700: #B42318;
    --rose50: #FFF1F3;
    --rose500: #F63D68;
    --rose700: #C01048;
    --orange50: #FFF7E9;
    --orange400: #FECA6B;
    --orange500: #FDB022;
    --orange600: #E6A01F;
    --orange700: #B47D18;
    --blue50: #F0F9FF;
    --blue400: #36BFFA;
    --blue500: #0BA5EC;
    --blue700: #026AA2;
    --blue900: #407BFF;
    --purple50: #F4F3FF;
    --purple500: #7A5AF8;
    --purple700: #5925DC;
    --neutral300: #B2B2B2;
}

@font-face {
    font-family: 'NunitoSans';
    src: url('./assets/fonts/NunitoSans-VariableFont.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$homes-web-app-primary: mat.define-palette(mat.$indigo-palette);
$homes-web-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$homes-web-app-warn: mat.define-palette(mat.$red-palette);

$custom-font-family: 'NunitoSans', sans-serif;

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$homes-web-app-theme: mat.define-light-theme((color: (primary: $homes-web-app-primary,
                accent: $homes-web-app-accent,
                warn: $homes-web-app-warn,
            ),
            typography: mat.define-typography-config($font-family: $custom-font-family ),
            density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($homes-web-app-theme);

@import 'ngx-toastr/toastr';
@import 'quill/dist/quill.snow.css';

*::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 10px;
}

*::-webkit-scrollbar-button {
    display: none;
}

*::-webkit-scrollbar-corner {
    display: none;
}

*::-webkit-scrollbar-track {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: transparent;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'NunitoSans', sans-serif;
    font-feature-settings: "ss01";
    font-optical-sizing: auto;
}

html {
    scroll-behavior: smooth;
}

body {
    max-height: 100vh;
}

input {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="radio"] {
    accent-color: var(--neonBlueColor);
    transform: scale(1.2);
    cursor: pointer;
}

textarea {
    resize: none;
    outline: none;
}

.font-weight-200 {
    font-variation-settings: "wght" 200;
}

.font-weight-300 {
    font-variation-settings: "wght" 300;
}

.font-weight-400 {
    font-variation-settings: "wght" 400;
}

.font-weight-500 {
    font-variation-settings: "wght" 500;
}

.font-weight-600 {
    font-variation-settings: "wght" 600;
}

.font-weight-700 {
    font-variation-settings: "wght" 700;
}

.font-weight-800 {
    font-variation-settings: "wght" 800;
}

.font-weight-900 {
    font-variation-settings: "wght" 900;
}

.font-weight-1000 {
    font-variation-settings: "wght" 1000;
}

// loader animation css
@keyframes loader-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 12px solid var(--gray100);
    border-top: 12px solid var(--neonBlueColor);
    animation: loader-rotate 1s cubic-bezier(0.32, 0.72, 0.31, 0.87) infinite;
}

// success animation css
.check-circle {
    position: relative;
    z-index: 1;
    width: 70px;
    height: 70px;

    @keyframes check-scale-animation {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(1.6);
        }
    }

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        left: 0px;
        border-radius: 50%;
        z-index: -1;
        background-color: var(--indigo100);
        animation: check-scale-animation .8s cubic-bezier(0.28, -0.12, 0, 1.11) 1;
    }

    @keyframes check-rotate {
        from {
            transform: rotate(320deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .check-svg {
        width: 70px;
        animation: check-rotate 1s cubic-bezier(0.42, 0, 0.01, 1.05) 1;
    }
}


// skeleton animation css
.skeleton {
    background: linear-gradient(90deg, #dddddd 25%, #e8e8e8 50%, #dddddd 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite ease-in-out;
    border-radius: 4px;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}


// table css
.table-section {
    table {
        width: 100%;
        border-collapse: collapse;

        tr {
            th {
                position: sticky;
                top: 0px;
                padding: 10px 15px;
                font-size: 12px;
                color: var(--gray500);
                background-color: var(--gray50);
                z-index: 2;
            }

            td,
            th {
                border: 1px solid var(--gray300);
                padding: 15px;
            }

            td {
                font-size: 14px;
                color: var(--blackColor);
            }
        }
    }

    table tr:first-child th,
    table tr:first-child td {
        border-top: none;
    }

    table tr:last-child th,
    table tr:last-child td {
        border-bottom: none;
    }

    table tr td:first-child,
    table tr th:first-child {
        border-left: none;
    }

    table tr td:last-child,
    table tr th:last-child {
        border-right: none;
    }
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.ph-x {
    cursor: pointer;
}

// Mat-Checkbox styles
mat-checkbox {
    --mdc-checkbox-selected-focus-icon-color: var(--neonBlueColor);
    --mdc-checkbox-selected-pressed-icon-color: var(--neonBlueColor);
    --mdc-checkbox-selected-hover-icon-color: var(--neonBlueColor);
    --mdc-checkbox-selected-icon-color: var(--neonBlueColor);
    --mdc-checkbox-selected-pressed-state-layer-color: transparent;
    --mdc-checkbox-unselected-focus-state-layer-color: transparent;
    --mdc-checkbox-selected-focus-state-layer-color: transparent;
    --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
    --mdc-checkbox-selected-hover-state-layer-opacity: 0;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;
    transform: scale(.7);

    .mat-mdc-checkbox-ripple {
        display: none;
    }
}

// Mat-Tabs Styles
.mat-mdc-tab-group {
    --mat-tab-header-label-text-size: 13px;
    --mdc-tab-indicator-active-indicator-color: var(--neonBlueColor);
    --mat-tab-header-active-ripple-color: transparent;
    --mat-tab-header-active-label-text-color: var(--neonBlueColor);
    --mat-tab-header-active-focus-label-text-color: var(--neonBlueColor);
    --mat-tab-header-active-hover-label-text-color: var(--neonBlueColor);

    .mat-mdc-tab-labels {
        border-bottom: 1px solid var(--gray200);

        .mat-mdc-tab {
            flex-grow: 0 !important;
            letter-spacing: normal;
            color: var(--neonBlueColor);
        }
    }
}

//USE THIS CLASS FOR FULL SCREEN MAT TABS
.renovations {
    .mat-mdc-tab-body-content {
        height: calc(100vh - 200px);
        overflow-y: auto;
    }
}

.hide-tabs {
    .mat-mdc-tab-label-container {
        display: none;
    }
}

.headers-tabs{
    .mat-mdc-tab-body-content{
        height: 530px;
    }
}

//  Page Start Header Styles

.page-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray200);
    padding: 30px 16px 15px 25px;

    .heading {
        color: var(--blackColor);
        font-size: 20px;
    }

    i {
        cursor: pointer;
        font-size: 20px;
    }
}

.page-header-toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 20px 25px;

    .toolbar-items-flex {
        display: flex;
        align-items: center;
        column-gap: 15px;
        position: relative;

        .toolbar-text {
            font-size: 12px;
            color: var(--gray400);
        }

        .filter-dot-wrapper {
            position: relative;
            z-index: 20;

            .circle {
                width: 8px;
                height: 8px;
                background-color: var(--blue900);
                border-radius: 50%;
                position: absolute;
                top: -2px;
                right: 0;
                z-index: 99999;
            }
        }

    }
}

// End of  Page Start Header Styles 

// Pdf viewer styling
.ng2-pdf-viewer-container {
    overflow-x: hidden !important;
}